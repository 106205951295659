import MomentUtils from '@date-io/moment';
import {LinearProgress} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment, {Moment} from 'moment';
import {
  fieldPropTypes,
  InjectedFieldProps,
  PublicFieldProps,
} from 'ra-ui-materialui/lib/field/types';
import React, {FC, memo, useCallback, useEffect, useState} from 'react';
import {useNotify} from 'react-admin';
import {Daily, Puzzle} from '../util/model';
import {baseUrl, httpClient} from '../util/transport';

const PuzzleDatePickerField: FC<PuzzleDatePickerFieldProps> = memo<PuzzleDatePickerFieldProps>(
  ({record = {}}) => {
    const puzzle = record as Puzzle;
    const [reloading, setReloading] = useState(true);
    const [selectedDate, setSelectedDate] = useState<Moment | null>(
      puzzle.date ? moment(puzzle.date) : null
    );
    const notify = useNotify();
    const [available, setAvailable] = useState<string[]>();

    const getMonth = useCallback(
      (start: Moment | null) => {
        setReloading(true);
        const sm = start!.clone().add(-1, 'month');
        const em = start!.clone().add(2, 'month');
        return httpClient(
          `${baseUrl}/daily/available?start=${sm.format(
            'YYYY-MM-DD'
          )}&end=${em.format('YYYY-MM-DD')}`
        )
          .then(({json: dates}: {json: string[]}) => {
            setAvailable(dates);
          })
          .catch((error) => {
            console.error(error);
            notify('Unable to check available dates!', 'error');
          })
          .finally(() => {
            setReloading(false);
          });
      },
      [notify]
    );

    const putPuzzle = (m: Moment | null, id: number) => {
      setReloading(true);
      httpClient(
        `${baseUrl}/daily/${m!.year()}/${
          m!.month() + 1
        }/${m?.date()}/${id}?start=2000-01-01&end=2000-01-01`,
        {
          method: selectedDate ? 'PATCH' : 'PUT',
        }
      )
        .then(({json: dailies}: {json: Daily[]}) => {
          setSelectedDate(m);
          getMonth(m);
        })
        .catch((error) => {
          console.error(error);
          notify('That did not work!', 'warning');
        })
        .finally(() => {
          setReloading(false);
        });
    };

    useEffect(() => {
      getMonth(moment());
    }, [getMonth]);

    const handleDateChange = (date: Moment | null) => {
      putPuzzle(date, puzzle.id);
    };

    const shouldDisableDate = (day: Moment | null) => {
      if (!day || selectedDate?.isSame(day, 'day')) {
        return false;
      }

      return !available ? false : !available.includes(day.format('YYYY-MM-DD'));
    };

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          margin="normal"
          disabled={moment(puzzle.date).isBefore(moment().add(1, 'day'), 'day')}
          id="date-picker-dialog"
          format="MM/DD/yyyy"
          value={selectedDate}
          shouldDisableDate={shouldDisableDate}
          onChange={handleDateChange}
          onMonthChange={getMonth}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <LinearProgress className={reloading ? '' : 'hide'} />
      </MuiPickersUtilsProvider>
    );
  }
);

PuzzleDatePickerField.defaultProps = {
  addLabel: true,
};

PuzzleDatePickerField.propTypes = {
  ...fieldPropTypes,
};

export interface PuzzleDatePickerFieldProps
  extends PublicFieldProps,
    InjectedFieldProps {}

PuzzleDatePickerField.displayName = 'PuzzleDatePickerField';

export default PuzzleDatePickerField;
