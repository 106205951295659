// in src/authProvider.js
import {AUTH_CHECK, AUTH_LOGIN, AUTH_LOGOUT} from 'react-admin';
import {AccessToken} from './model';
import {authBaseUrl, baseUrl, httpClient} from './transport';

export const authProvider = (type: string, params: any) => {
  switch (type) {
    case AUTH_LOGIN:
      return httpClient(`${authBaseUrl}/login`, {
        method: 'POST',
        body: JSON.stringify({
          google_apps_access_token: params,
        }),
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error('Access denied.');
          }

          return response.json;
        })
        .then(Promise.resolve)
        .catch(Promise.reject);
    case AUTH_CHECK:
      return httpClient(`${baseUrl}/me`)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error('Access denied.');
          }

          return Promise.resolve();
        })
        .catch(() => {
          window.location.pathname = '/login';
          return Promise.reject();
        });
    case AUTH_LOGOUT:
      return httpClient(`${authBaseUrl}/logout`, {
        method: 'POST',
      })
        .then((response) => {
          if (response.status !== 204) {
            throw new Error('Something went wrong!');
          }
        })
        .catch(() => {
          return Promise.reject();
        });
  }
  return Promise.resolve();
};
