import {
  fieldPropTypes,
  InjectedFieldProps,
  PublicFieldProps,
} from 'ra-ui-materialui/lib/field/types';
import * as React from 'react';
import {FC, memo} from 'react';
import Solution from '../components/Solution';
import {Spectrum} from '../util/model';
import './BoardDetailField.scss';

const BoardDetailField: FC<BoardDetailFieldProps> = memo<BoardDetailFieldProps>(
  ({record = {}}) => {
    const spectrum = record as Spectrum;
    return (
      <div>
        {spectrum.boards.map((board) => (
          <Solution spectrum={spectrum} board={board} />
        ))}
      </div>
    );
  }
);

BoardDetailField.defaultProps = {
  addLabel: true,
};

BoardDetailField.propTypes = {
  ...fieldPropTypes,
};

export interface BoardDetailFieldProps
  extends PublicFieldProps,
    InjectedFieldProps {}

BoardDetailField.displayName = 'BoardsField';

export default BoardDetailField;
