import React, {FC, memo, useMemo} from 'react';
import {Link} from 'react-admin';
import {MeshWordRole, Puzzle, Word} from '../util/model';
import './ProposePuzzle.scss';

type Props = {
  puzzle?: Puzzle;
};

export const WordList: FC<Props> = memo<Props>(({puzzle}) => {
  const coalescedWords = useMemo(
    () =>
      puzzle?.mesh.words.reduce<{[key in MeshWordRole | number]: Word[]}>(
        (accumulator, current) => {
          if (!accumulator[current.role]) {
            accumulator[current.role] = [];
          }
          accumulator[current.role].push(current.word);
          return accumulator;
        },
        {}
      ),
    [puzzle]
  );

  return (
    <ul className="monospace">
      {puzzle?.id ? (
        <li>
          <a href={`/puzzles/${puzzle?.id}/show`}>Permalink</a>
        </li>
      ) : null}
      <li>Difficulty: {puzzle?.difficulty}</li>
      <li>
        Rows
        <ul>
          {coalescedWords?.[MeshWordRole.Row]?.map(({word, id}) => (
            <li key={id}>
              <Link to={`/words/${id}`}>{word}</Link>
            </li>
          ))}
        </ul>
      </li>
      <li>
        Columns
        <ul>
          {coalescedWords?.[MeshWordRole.Column]?.map(({word, id}) => (
            <li key={id}>
              <Link to={`/words/${id}`}>{word}</Link>
            </li>
          ))}
        </ul>
      </li>
      <li>
        Anagrams
        <ul>
          {coalescedWords?.[MeshWordRole.Anagram]?.map(({word, id}) => (
            <li key={id}>
              <Link to={`/words/${id}`}>{word}</Link>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
});
