import React from 'react';
import {
  AutocompleteArrayInput,
  Datagrid,
  Filter,
  FilterProps,
  List,
  ListProps,
  Loading,
  NumberInput,
  ReferenceArrayInput,
  Show,
  ShowProps,
  SimpleShowLayout,
  useListContext,
} from 'react-admin';
import BoardDetailField from '../custom-fields/BoardDetailField';
import BoardsField from '../custom-fields/BoardsField';
import SpectrumField from '../custom-fields/SpectrumField';
import {exactFilter, fuzzyFilter, gteFilter, inFilter} from '../util/filters';

const filterToQuery = (q: string) =>
  (q && {word: fuzzyFilter.parse!(q, '')}) || {};

const SpectrumFilter = (props: Partial<FilterProps>) => {
  return (
    <Filter {...props}>
      <ReferenceArrayInput
        label="With words..."
        source="with_word_ids"
        reference="words"
        alwaysOn
        allowEmpty
        filterToQuery={filterToQuery}
        perPage={5}
        sort={{field: 'word', order: 'ASC'}}
        {...inFilter}
      >
        <AutocompleteArrayInput optionText="word" />
      </ReferenceArrayInput>
      <NumberInput label="Columns" source="w" alwaysOn {...exactFilter} />
      <NumberInput label="Rows" source="h" alwaysOn {...exactFilter} />
      <NumberInput
        label="Min regions"
        source="min_regions"
        alwaysOn
        {...gteFilter}
        min={1}
        max={8}
      />
      <NumberInput
        label="Max regions"
        source="max_regions"
        alwaysOn
        {...gteFilter}
        min={1}
        max={8}
      />
      <NumberInput
        label="Min anagram length"
        source="min_anagram_length"
        alwaysOn
        {...gteFilter}
        min={2}
        max={15}
      />
    </Filter>
  );
};

export const SpectrumGrid = () => {
  const {loading} = useListContext();
  if (loading) {
    return <Loading />;
  }

  return (
    <Datagrid
      rowClick={(id, basePath) =>
        `${basePath}/${id}/show${window.location.search}`
      }
    >
      <BoardsField label="Boards" />
    </Datagrid>
  );
};

export const SpectrumList = (props: ListProps) => {
  return (
    <List
      filters={<SpectrumFilter />}
      bulkActionButtons={false}
      exporter={false}
      {...props}
    >
      <SpectrumGrid />
    </List>
  );
};

export const SpectrumShow = (props: ShowProps) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <SpectrumField label="Spectrum" />
        <BoardDetailField label="Boards" />
      </SimpleShowLayout>
    </Show>
  );
};
