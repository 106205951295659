import PuzzleIcon from '@material-ui/icons/ExtensionRounded';
import PackIcon from '@material-ui/icons/FormatListNumbered';
import SpectrumIcon from '@material-ui/icons/GridOnRounded';
import WordIcon from '@material-ui/icons/TranslateRounded';
import {createBrowserHistory as createHistory} from 'history';
import React, {useEffect, useState} from 'react';
import {Admin, Loading, LoginComponent, Resource} from 'react-admin';
import Dashboard from './custom-routes/Dashboard';
import Login from './custom-routes/Login';
import {PackCreate, PackEdit, PackList, PackShow} from './resources/packs';
import {PuzzleEdit, PuzzleList, PuzzleShow} from './resources/puzzles';
import {SpectrumList, SpectrumShow} from './resources/spectrums';
import {WordEdit, WordList} from './resources/words';
import {customRoutes} from './routes';
import {authProvider} from './util/auth';
import {baseUrl, dataProvider, httpClient} from './util/transport';

const history = createHistory();

const App = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    httpClient(`${baseUrl}/me`)
      .then(() => {
        setLoading(false);
        if (window.location.pathname === '/login') {
          window.location.pathname = '/';
        }
      })
      .catch((e) => {
        console.error(e);
        if (window.location.pathname !== '/login') {
          window.location.pathname = '/login';
        } else {
          setLoading(false);
        }
      });
  }, []);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Initializing...."
        loadingSecondary="Checking credentials"
      />
    );
  }

  return (
    <Admin
      disableTelemetry
      dashboard={Dashboard}
      dataProvider={dataProvider}
      history={history}
      customRoutes={customRoutes}
      authProvider={authProvider}
      loginPage={Login as LoginComponent}
    >
      <Resource name="words" list={WordList} icon={WordIcon} edit={WordEdit} />
      <Resource
        options={{label: 'Spectra'}}
        name="spectrums"
        list={SpectrumList}
        show={SpectrumShow}
        icon={SpectrumIcon}
      />
      <Resource
        options={{label: 'Puzzles'}}
        name="puzzles"
        list={PuzzleList}
        show={PuzzleShow}
        edit={PuzzleEdit}
        icon={PuzzleIcon}
      />
      <Resource
        options={{label: 'Packs'}}
        name="packs"
        create={PackCreate}
        list={PackList}
        show={PackShow}
        icon={PackIcon}
        edit={PackEdit}
      />
    </Admin>
  );
};

export default App;
