import {
  fieldPropTypes,
  InjectedFieldProps,
  PublicFieldProps,
} from 'ra-ui-materialui/lib/field/types';
import * as React from 'react';
import {FC, memo} from 'react';
import Mesh from '../components/Mesh';
import Solution from '../components/Solution';
import {Puzzle} from '../util/model';
import './BoardDetailField.scss';

const SolutionField: FC<SolutionFieldProps> = memo<SolutionFieldProps>(
  ({record = {}}) => {
    const puzzle = record as Puzzle;
    return (
      <>
        <div className="divided board tight">
          <Mesh mesh={puzzle.mesh} />
        </div>
        <Solution
          spectrum={puzzle.mesh.board.spectrum}
          board={puzzle.mesh.board}
        />
      </>
    );
  }
);

SolutionField.defaultProps = {
  addLabel: true,
};

SolutionField.propTypes = {
  ...fieldPropTypes,
};

export interface SolutionFieldProps
  extends PublicFieldProps,
    InjectedFieldProps {}

SolutionField.displayName = 'SolutionField';

export default SolutionField;
