import {Fab, Tooltip} from '@material-ui/core';
import {BuildOutlined, ExtensionOutlined} from '@material-ui/icons';
import React, {FC, memo, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {colorMap} from '../util/grid';
import {Mesh as MeshModel, MeshWordRole} from '../util/model';
import GridWord from './GridWord';

const Mesh: FC<MeshProps> = memo<MeshProps>(({mesh, configurable}) => {
  const anagrams = mesh.words.filter(
    (meshWord) => meshWord.role === MeshWordRole.Anagram
  );
  const text = mesh.puzzle ? 'View puzzle' : 'Propose puzzle';
  const destination = mesh.puzzle
    ? `/puzzles/${mesh.puzzle.id}/show`
    : `/meshes/${mesh.id}/propose`;
  const icon = mesh.puzzle ? <ExtensionOutlined /> : <BuildOutlined />;

  // const sortedAnagrams = useMemo(() => [...anagrams].sort((a, b) => {
  //   return a.word.word.length - b.word.word.length;
  // }), [anagrams])
  return (
    <div className="mesh">
      {configurable && mesh.id > 0 && (
        <Tooltip title={text} aria-label={text} className="mesh-button">
          <Link to={destination}>
            <Fab size="small">{icon}</Fab>
          </Link>
        </Tooltip>
      )}
      <div className="holder">
        {anagrams.map(({word}, idx) => (
          <>
            <Link to={`/words/${word.id}/edit`} className="link">
              <GridWord
                word={word.word}
                style={{
                  backgroundColor: colorMap[idx],
                }}
              />
            </Link>
            {idx !== anagrams.length - 1 && <div className="slash" />}
          </>
        ))}
      </div>
    </div>
  );
});

export interface MeshProps {
  mesh: MeshModel;
  configurable?: boolean;
}

export default Mesh;
