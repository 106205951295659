import {
  Button,
  Grid as UIGrid,
  LinearProgress,
  Slider,
  Typography,
} from '@material-ui/core';
import {sum, throttle} from 'lodash';
import React, {FC, memo, useCallback, useEffect, useRef, useState} from 'react';
import {useAuthenticated, useNotify} from 'react-admin';
import {useAsyncFn} from 'react-use';
import {MeshWordRole, Puzzle} from '../util/model';
import {baseUrl, httpClient} from '../util/transport';
import Mesh from './Mesh';
import './ProposePuzzle.scss';
import PuzzleComponent from './Puzzle';
import Solution from './Solution';
import {WordList} from './WordList';

const useThrottle = (cb: () => Promise<void>) => {
  const cbRef = useRef(cb);
  useEffect(() => {
    cbRef.current = cb;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle(() => cbRef.current(), 1000, {leading: false, trailing: true}),
    []
  );
};

export const useToggle = (
  meshId: number,
  difficulty = 25,
  currentPuzzle?: Puzzle
) => {
  const [puzzle, setPuzzle] = useState(currentPuzzle);
  const notify = useNotify();
  const [{loading: toggleLoading}, toggle] = useAsyncFn(
    async (x: number, y: number) => {
      const gridIndex = y * puzzle!.mesh.board.spectrum.w + x;
      const region = Number(puzzle!.mesh.board.partition[gridIndex]);
      const anagrams = puzzle!.mesh.words
        .filter(({role}) => role === MeshWordRole.Anagram)
        .map(({word: {word}}) => word);
      const anagramOffsets = anagrams.reduce<number[]>(
        (accumulator, word) => {
          accumulator.push(word.length + accumulator[accumulator.length - 1]);
          return accumulator;
        },
        [0]
      );
      const anagram = anagrams[region];
      const doMask = puzzle!.mask[gridIndex] === '0';
      // TODO: let the user choose!
      const anagramIndex =
        anagramOffsets[region] +
        anagram
          .split('')
          .map((c, i) => ({c, i}))
          .filter(({c}) => c === puzzle!.mesh.board.spectrum.letters[gridIndex])
          .find(
            ({i}) =>
              puzzle!.mesh_mask[anagramOffsets[region] + i] ===
              (doMask ? '0' : '1')
          )!.i;

      const [gridPrefix, gridSuffix] = [
        puzzle!.mask.slice(0, gridIndex),
        puzzle!.mask.slice(gridIndex + 1),
      ];

      const [anagramPrefix, anagramSuffix] = [
        puzzle!.mesh_mask.slice(0, anagramIndex),
        puzzle!.mesh_mask.slice(anagramIndex + 1),
      ];

      const mask = `${gridPrefix}${doMask ? '1' : '0'}${gridSuffix}`;
      const mesh_mask = `${anagramPrefix}${doMask ? '1' : '0'}${anagramSuffix}`;

      try {
        const {json}: {json: Puzzle} = await httpClient(
          `${baseUrl}/meshes/${meshId}/check`,
          {
            method: 'POST',
            body: JSON.stringify({mask, mesh_mask}),
          }
        );
        setPuzzle(json);
      } catch (e) {
        console.error(e);
        notify('Cannot toggle this cell!', 'error');
      }
    },
    [meshId, notify, puzzle]
  );

  const [
    {loading: getProposalLoading},
    rawGetProposal,
  ] = useAsyncFn(async () => {
    try {
      const {json}: {json: Puzzle} = await httpClient(
        `${baseUrl}/meshes/${meshId}/propose`,
        {
          method: 'POST',
          body: JSON.stringify({
            difficulty,
          }),
        }
      );
      setPuzzle(json);
      if ((json.difficulty ?? 0) < difficulty) {
        notify(
          `Puzzle difficulty ${json.difficulty} is lower than requested difficulty ${difficulty}`,
          'warning'
        );
      } else {
        notify(
          `Puzzle difficulty ${json.difficulty} satisfies requested difficulty ${difficulty}`
        );
      }
    } catch (e) {
      console.error(e);
      notify('Unable to propose puzzle for mesh!', 'error');
    }
  }, [difficulty, meshId, notify]);
  const getProposal = useThrottle(rawGetProposal);

  const [{loading: saveLoading}, save] = useAsyncFn(async () => {
    if (!puzzle) {
      return;
    }
    try {
      const {json}: {json: Puzzle} = await httpClient(
        `${baseUrl}/meshes/${meshId}/puzzle`,
        {
          method: 'POST',
          body: JSON.stringify({
            mask: puzzle.mask,
            mesh_mask: puzzle.mesh_mask,
          }),
        }
      );
      setPuzzle(json);
    } catch (e) {
      console.error(e);
      notify('Unable to save puzzle!', 'error');
    }
  }, [meshId, puzzle, notify]);

  return {
    loading: toggleLoading || getProposalLoading || saveLoading,
    toggle,
    getProposal,
    puzzle,
    save,
  };
};

type Props = {
  id: number;
  onCreate?: (id: number) => void;
  defaultDifficulty?: number;
};

const ProposePuzzle: FC<Props> = memo<Props>(
  ({id, onCreate, defaultDifficulty = 25}) => {
    useAuthenticated();
    const [invalidAlternatives, setInvalidAlternatives] = useState(false);
    const [difficulty, setDifficulty] = useState<number>(defaultDifficulty);
    const {puzzle, toggle, getProposal, loading, save} = useToggle(
      id,
      difficulty
    );

    useEffect(() => {
      setDifficulty(defaultDifficulty);
    }, [defaultDifficulty]);

    useEffect(() => {
      if (!puzzle) {
        return;
      }

      if (puzzle?.id) {
        onCreate?.(puzzle.id);
        return;
      }

      setInvalidAlternatives(
        sum(puzzle.alternatives?.flatMap((board) => board.meshes?.length)) > 1
      );
    }, [onCreate, puzzle]);

    const onDifficultyChange = useCallback(
      (_event: React.ChangeEvent<{}>, value: number | number[]) =>
        setDifficulty(value as number),
      []
    );

    useEffect(() => {
      getProposal();
    }, [difficulty, getProposal]);

    return (
      <div>
        {puzzle && (
          <>
            <div className="divided board tight">
              <Mesh mesh={puzzle.mesh} />
            </div>
            <Solution
              spectrum={puzzle.mesh.board.spectrum}
              board={puzzle.mesh.board}
            />
            <WordList puzzle={puzzle} />
          </>
        )}
        <UIGrid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className="divided"
        >
          <UIGrid container>
            <UIGrid item sm={9}>
              <Typography gutterBottom>Difficulty</Typography>
              <Slider
                defaultValue={difficulty}
                onChange={onDifficultyChange}
                step={5}
                marks
                min={0}
                max={200}
                valueLabelDisplay="auto"
              />
            </UIGrid>
          </UIGrid>
          <UIGrid container spacing={1}>
            <UIGrid item sm={9}>
              <LinearProgress className={loading ? undefined : 'hide'} />
            </UIGrid>
          </UIGrid>
        </UIGrid>
        {puzzle ? (
          <PuzzleComponent
            puzzle={puzzle}
            onClick={loading ? undefined : toggle}
          />
        ) : null}
        {invalidAlternatives && (
          <div className="warnings">
            {puzzle?.alternatives?.map((board, idx) => (
              <Solution key={idx} spectrum={board.spectrum} board={board} />
            ))}
          </div>
        )}
        <div className="divided">
          <Button
            variant="contained"
            color="primary"
            disabled={loading || invalidAlternatives}
            onClick={save}
          >
            Create puzzle
          </Button>
        </div>
      </div>
    );
  }
);

export default ProposePuzzle;
