import {Card, CardContent} from '@material-ui/core';
import React, {FC, memo} from 'react';
import {Title} from 'react-admin';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import ProposePuzzle from '../components/ProposePuzzle';
import '../components/ProposePuzzle.scss';

const ProposePuzzleRoute: FC<ProposePuzzleProps> = memo<ProposePuzzleProps>(
  ({
    match: {
      params: {id},
    },
  }) => {
    const {push} = useHistory();
    return (
      <Card>
        <Title title={`Propose puzzles for mesh #${id}`} />
        <CardContent>
          <ProposePuzzle
            id={Number(id)}
            onCreate={(id) => push(`/puzzles/${id}/show`)}
          />
        </CardContent>
      </Card>
    );
  }
);

type ProposePuzzleProps = RouteComponentProps<{
  id: string;
}>;

export default withRouter(ProposePuzzleRoute);
