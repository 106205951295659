import * as React from 'react';
import {sum} from 'lodash';
import {
  fieldPropTypes,
  InjectedFieldProps,
  PublicFieldProps,
} from 'ra-ui-materialui/lib/field/types';
import {FC, memo, useEffect, useMemo, useState} from 'react';
import {LinearProgress, SaveButton} from 'react-admin';
import {useToggle} from '../components/ProposePuzzle';
import PuzzleComponent from '../components/Puzzle';
import Solution from '../components/Solution';
import {Puzzle} from '../util/model';
import './BoardDetailField.scss';

const PuzzleField: FC<PuzzleProps> = memo<PuzzleProps>(
  ({record = {id: 0}, source, editable}) => {
    const [disableSave, setDisableSave] = useState(false);
    const derivedPuzzle = source
      ? (record[source.split('.').pop()!] as Puzzle)
      : (record as Puzzle);
    const {puzzle, toggle, loading, save} = useToggle(
      derivedPuzzle.mesh.id,
      undefined,
      derivedPuzzle
    );

    const isInvalid = useMemo(
      () =>
        sum(puzzle?.alternatives?.flatMap((board) => board.meshes?.length)) > 1,
      [puzzle]
    );

    useEffect(() => {
      setDisableSave(isInvalid);
    }, [isInvalid]);

    return (
      <>
        <PuzzleComponent
          puzzle={puzzle ?? derivedPuzzle}
          onClick={editable ? toggle : undefined}
        />
        {loading ? <LinearProgress /> : null}
        {editable ? (
          <SaveButton disabled={disableSave} handleSubmitWithRedirect={save} />
        ) : null}
        {isInvalid ? (
          <div className="warnings">
            {puzzle?.alternatives?.map((board, idx) => (
              <Solution key={idx} spectrum={board.spectrum} board={board} />
            ))}
          </div>
        ) : null}
      </>
    );
  }
);

PuzzleField.defaultProps = {
  addLabel: true,
};

PuzzleField.propTypes = {
  ...fieldPropTypes,
};

export interface PuzzleProps extends PublicFieldProps, InjectedFieldProps {
  editable?: boolean;
}

PuzzleField.displayName = 'PuzzleField';

export default PuzzleField;
