import React, {FC, memo} from 'react';
import {getColorizer} from '../util/grid';
import {Board, Spectrum} from '../util/model';
import Grid from './Grid';
import Mesh from './Mesh';

const Solution: FC<SolutionProps> = memo<SolutionProps>(
  ({board, spectrum, withAnagrams = true}) => {
    const colorizer = getColorizer(spectrum);
    return (
      <div className="board">
        <div>
          <Grid spectrum={spectrum} mapXYToStyle={colorizer(board)} />
        </div>
        {withAnagrams && (
          <div className="anagrams">
            {board.meshes?.map((mesh) => (
              <Mesh mesh={mesh} configurable />
            ))}
          </div>
        )}
      </div>
    );
  }
);

export interface SolutionProps {
  board: Board;
  spectrum: Spectrum;
  withAnagrams?: boolean;
}

export default Solution;
