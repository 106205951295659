import React, {useState} from 'react';
import {
  Datagrid,
  Edit,
  EditProps,
  List,
  ListProps,
  SaveButton,
  Show,
  ShowProps,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  Toolbar,
} from 'react-admin';
import PuzzleDatePickerField from '../custom-fields/PuzzleDatePickerField';
import PuzzleField from '../custom-fields/PuzzleField';
import SolutionField from '../custom-fields/SolutionField';

export const PuzzleList = (props: ListProps) => {
  return (
    <List bulkActionButtons={false} exporter={false} {...props}>
      <Datagrid rowClick="edit">
        <PuzzleField label="Puzzle" />
        <SolutionField label="Solution" />
        <TextField source="date" />
      </Datagrid>
    </List>
  );
};

export const PuzzleShow = (props: ShowProps) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <PuzzleDatePickerField label="Date" />
        <PuzzleField label="Puzzle" />
        <SolutionField label="Solution" />
      </SimpleShowLayout>
    </Show>
  );
};

export const PuzzleEdit = (props: EditProps) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<div />}>
        <SolutionField label="Solution" />
        <PuzzleField label="Puzzle" editable />
      </SimpleForm>
    </Edit>
  );
};
