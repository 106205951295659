import {UseFieldConfig} from 'react-final-form';

export interface Filter {
  op: string;
  value: any;
}

const makeFilter = (op: string): Partial<UseFieldConfig<Filter>> => ({
  parse: (v) => v && {op, value: v},
  format: (v) => v && v.value,
});

export const fuzzyFilter = makeFilter('~');
export const exactFilter = makeFilter('=');
export const lteFilter = makeFilter('<=');
export const gteFilter = makeFilter('>=');
export const inFilter = makeFilter('∈');
