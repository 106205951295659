import {
  Button,
  Dialog,
  Grid as UIGrid,
  LinearProgress,
  Slider,
  Typography,
} from '@material-ui/core';
import {
  fieldPropTypes,
  InjectedFieldProps,
  PublicFieldProps,
} from 'ra-ui-materialui/lib/field/types';
import * as React from 'react';
import {FC, memo, useCallback, useState} from 'react';
import {useNotify, useRefresh} from 'react-admin';
import ProposePuzzle from '../components/ProposePuzzle';
import {DialogContent, DialogTitle} from '../custom-routes/Dashboard';
import {Mesh, Pack} from '../util/model';
import {baseUrl, httpClient} from '../util/transport';
import './BoardDetailField.scss';

const PackrInput: FC<Props> = memo<Props>(({record = {id: 0}, source}) => {
  const pack = record as Pack;
  const [width, setWidth] = useState(4);
  const [height, setHeight] = useState(4);
  const [difficulty, setDifficulty] = useState(50);

  const onWidthChange = useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) =>
      setWidth(value as number),
    []
  );

  const onHeightChange = useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) =>
      setHeight(value as number),
    []
  );

  const onDifficultyChange = useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) =>
      setDifficulty(value as number),
    []
  );

  const notify = useNotify();
  const refresh = useRefresh();

  const getMeshIdForProposal = useCallback(() => {
    setMeshIdForProposal(undefined);

    httpClient(`${baseUrl}/meshes/suggest?width=${width}&height=${height}`)
      .then(({json: {id}}: {json: Mesh}) => {
        setMeshIdForProposal(id);
      })
      .catch((error) => {
        console.error(error);
        notify('That did not work!', 'warning');
      });
  }, [height, notify, width]);

  const [open, setOpen] = useState(false);
  const [meshIdForProposal, setMeshIdForProposal] = useState<number>();

  const handleClose = useCallback(() => {
    setMeshIdForProposal(undefined);
    setOpen(false);
  }, [setMeshIdForProposal, setOpen]);

  const onAddPuzzle = useCallback(() => {
    setOpen(true);
    getMeshIdForProposal();
  }, [getMeshIdForProposal]);

  const discardMesh = useCallback(
    () =>
      httpClient(`${baseUrl}/meshes/${meshIdForProposal}`, {
        method: 'DELETE',
      })
        .then(() => {
          getMeshIdForProposal();
        })
        .catch(() => {
          console.error('error discarding mesh');
        }),
    [getMeshIdForProposal, meshIdForProposal]
  );

  const onCreate = useCallback(
    (id: number) => {
      if (!pack.puzzles) {
        return;
      }

      httpClient(`${baseUrl}/packs/${pack.id}`, {
        method: 'PUT',
        body: JSON.stringify({
          ...pack,
          puzzles: [
            ...(pack.puzzles ?? []),
            {
              pack_id: pack.id,
              puzzle_id: id,
              sequence: pack.puzzles.length,
            },
          ],
        }),
      })
        .then(() => {
          refresh();
        })
        .catch((error) => {
          console.error(error);
          notify('That did not work!', 'warning');
        })
        .finally(handleClose);
    },
    [handleClose, notify, pack, refresh]
  );

  return (
    <div>
      <UIGrid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className="divided"
      >
        <UIGrid container>
          <UIGrid item sm={9}>
            <Typography gutterBottom>Width</Typography>
            <Slider
              defaultValue={width}
              onChange={onWidthChange}
              step={1}
              marks
              min={3}
              max={5}
              valueLabelDisplay="auto"
            />
          </UIGrid>
          <UIGrid item sm={9}>
            <Typography gutterBottom>Height</Typography>
            <Slider
              defaultValue={height}
              onChange={onHeightChange}
              step={1}
              marks
              min={3}
              max={5}
              valueLabelDisplay="auto"
            />
          </UIGrid>
          <UIGrid item sm={9}>
            <Typography gutterBottom>Difficulty</Typography>
            <Slider
              defaultValue={difficulty}
              onChange={onDifficultyChange}
              step={5}
              marks
              min={0}
              max={200}
              valueLabelDisplay="auto"
            />
          </UIGrid>
          <UIGrid item sm={9}>
            <Button onClick={onAddPuzzle}>Add Puzzle</Button>
          </UIGrid>
        </UIGrid>
      </UIGrid>
      <Dialog onClose={handleClose} open={open} fullScreen>
        <DialogTitle onClose={handleClose}>
          <Button
            autoFocus
            disabled={!meshIdForProposal}
            onClick={getMeshIdForProposal}
            color="primary"
          >
            Try another
          </Button>
          <Button
            disabled={!meshIdForProposal}
            onClick={discardMesh}
            color="secondary"
          >
            Discard mesh (permanent)
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          {meshIdForProposal ? (
            <ProposePuzzle
              id={meshIdForProposal}
              defaultDifficulty={difficulty}
              onCreate={onCreate}
            />
          ) : (
            <LinearProgress />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
});

PackrInput.defaultProps = {
  addLabel: true,
};

PackrInput.propTypes = {
  ...fieldPropTypes,
};

interface Props extends PublicFieldProps, InjectedFieldProps {}

PackrInput.displayName = 'Packr';

export default PackrInput;
