import FullCalendar from '@fullcalendar/react';
import {EventInput} from '@fullcalendar/common';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  Button,
  Card,
  createStyles,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  LinearProgress,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import moment, {Moment} from 'moment';
import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {Error, Title, useNotify} from 'react-admin';
import {useHistory} from 'react-router-dom';
import ProposePuzzle from '../components/ProposePuzzle';
import Solution from '../components/Solution';
import {Daily, Mesh} from '../util/model';
import {baseUrl, httpClient} from '../util/transport';
import './Dashboard.scss';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const getRoleForDate = (date?: Date) => {
  return date?.getDay() === 0 ? 2 : 1;
};

const getQueryAddendumForDate = (date?: Date) => {
  switch (date?.getDay()) {
    // min_anagrams of 3 on M, T, W, F
    case 1:
    case 2:
    case 3:
    case 5:
      return '&min_anagrams=3';
    // max_anagrams of 2 on Th, Sa
    case 4:
    case 6:
      return '&max_anagrams=2';
    default:
      return '';
  }
};

const Dashboard: FC<DashboardProps> = () => {
  const [events, setEvents] = useState<EventInput[]>([]);
  const [reloading, setReloading] = useState(false);
  const [error, setError] = useState<Error>();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<Date>();
  const [meshIdForProposal, setMeshIdForProposal] = useState<number>();
  const [startDate, setStartDate] = useState<Moment>();
  const [endDate, setEndDate] = useState<Moment>();
  const [defaultDifficulty, setDefaultDifficulty] = useState(80);
  const fileInput = useRef<HTMLInputElement>(null);

  const notify = useNotify();
  const {push} = useHistory();
  const handleClose = () => {
    setMeshIdForProposal(undefined);
    setOpen(false);
  };
  const getMonth = (start: Moment, end: Moment): Promise<EventInput[]> => {
    setReloading(true);
    return httpClient(
      `${baseUrl}/daily?start=${start.format('YYYY-MM-DD')}&end=${end.format(
        'YYYY-MM-DD'
      )}`
    )
      .then(({json: dailies}: {json: Daily[]}) => {
        setEvents(dailies);
        return dailies as EventInput[];
      })
      .catch((error) => {
        setError(error);
        return [];
      })
      .finally(() => {
        setReloading(false);
      });
  };

  const putPuzzle = (start: Date, id: number, revert?: () => void) => {
    const year = start.getUTCFullYear();
    const month = start.getUTCMonth() + 1;
    const day = start.getUTCDate();
    setReloading(true);
    httpClient(
      `${baseUrl}/daily/${year}/${month}/${day}/${id}?start=${startDate!.format(
        'YYYY-MM-DD'
      )}&end=${endDate!.format('YYYY-MM-DD')}&role=${getRoleForDate(start)}`,
      {
        method: revert ? 'PATCH' : 'PUT',
      }
    )
      .then(({json: dailies}: {json: Daily[]}) => {
        setEvents(dailies);
      })
      .catch((error) => {
        console.error(error);
        notify('That did not work!', 'warning');
        revert && revert();
      })
      .finally(() => {
        setReloading(false);
      });
  };

  useEffect(() => {
    startDate && endDate && getMonth(startDate, endDate);
  }, [startDate, endDate]);

  const onBulkUpload = useCallback(
    ({target}: any) => {
      if (target.files.length !== 1) {
        console.info(target.files.length);
        return;
      }

      const data = new FormData();
      data.append('file', target.files[0]);
      setReloading(true);
      httpClient(`${baseUrl}/meshes`, {
        method: 'PATCH',
        body: data,
      })
        .then((response) => {
          if (response.status !== 202) {
            notify('Unable to upload meshes!', 'warning');
          } else {
            notify('Meshes accepted!');
          }
        })
        .catch((e) => {
          console.error(e);
          notify('An error occurred!', 'error');
        })
        .finally(() => {
          setReloading(false);
          fileInput.current!.value = '';
        });
    },
    [notify]
  );

  const getMeshIdForProposal = useCallback(
    (date?: Date) => {
      setMeshIdForProposal(undefined);
      const day = date?.getDay();
      const role = getRoleForDate(date);
      const queryAddendum = getQueryAddendumForDate(date);


      switch (day) {
        case 0:
          setDefaultDifficulty(70);
          break;
        case undefined:
          break;
        default:
          setDefaultDifficulty(10 * day);
          break;
      }

      httpClient(`${baseUrl}/meshes/suggest?role=${role}${queryAddendum}`)
        .then(({json: {id}}: {json: Mesh}) => {
          setMeshIdForProposal(id);
        })
        .catch((error) => {
          console.error(error);
          notify('That did not work!', 'warning');
        });
    },
    [notify]
  );

  const discardMesh = useCallback(
    () =>
      httpClient(`${baseUrl}/meshes/${meshIdForProposal}`, {
        method: 'DELETE',
      })
        .then(() => {
          getMeshIdForProposal(date);
        })
        .catch(() => {
          console.error('error discarding mesh');
        }),
    [getMeshIdForProposal, meshIdForProposal, date]
  );

  if (error) return <Error error={error} />;

  return (
    <Card>
      <Title title="Wordministration" />
      <CardContent>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          editable={!reloading}
          events={({start, end}, success, error) => {
            const startMoment = moment(start);
            if (!startDate || !startMoment.isSame(startDate, 'day')) {
              setEvents([]);
              setStartDate(startMoment);
              setEndDate(moment(end));
            } else {
              success(events);
            }
          }}
          height="auto"
          validRange={{
            start: '2021-10-01',
          }}
          eventContent={({event: {extendedProps: daily}}) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'center',
                gap: 4,
              }}
            >
              <Solution
                board={daily.puzzle.mesh.board}
                spectrum={daily.puzzle.mesh.board.spectrum}
                withAnagrams={false}
              />
              <p style={{textAlign: 'center'}}>
                Difficulty {daily.puzzle.difficulty}
              </p>
            </div>
          )}
          eventDrop={({
            revert,
            event: {
              _instance,
              _def: {
                extendedProps: {
                  puzzle: {id},
                },
              },
            },
          }) => {
            setEvents(events.filter(({puzzle: {id: thatId}}) => id !== thatId));
            const start = _instance!.range.start;
            putPuzzle(start, id, revert);
          }}
          eventClick={({
            event: {
              _def: {
                extendedProps: {
                  puzzle: {id},
                },
              },
            },
          }) => {
            push(`/puzzles/${id}/show`);
          }}
          dateClick={({date, dateStr}) => {
            setDate(date);
            setOpen(true);
            getMeshIdForProposal(date);
          }}
        />
        <div className="reloadingBarHolder">
          {reloading && <LinearProgress />}
        </div>
        <div className="buttonTray">
          <label htmlFor="bulk-upload">
            <input
              id="bulk-upload"
              type="file"
              ref={fileInput}
              onChange={onBulkUpload}
              hidden
            />
            <IconButton aria-label="bulk load" component="span">
              <CloudUploadIcon />
            </IconButton>
          </label>
        </div>
      </CardContent>
      <Dialog onClose={handleClose} open={open} fullScreen>
        <DialogTitle onClose={handleClose}>
          {date && <span>Puzzle for {date.toDateString()}</span>}
          <Button
            autoFocus
            disabled={!meshIdForProposal}
            onClick={() => getMeshIdForProposal(date)}
            color="primary"
          >
            Try another
          </Button>
          <Button
            disabled={!meshIdForProposal}
            onClick={discardMesh}
            color="secondary"
          >
            Discard mesh (permanent)
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          {meshIdForProposal ? (
            <ProposePuzzle
              id={meshIdForProposal}
              defaultDifficulty={defaultDifficulty}
              onCreate={(id) => {
                putPuzzle(date!, id);
                handleClose();
              }}
            />
          ) : (
            <LinearProgress />
          )}
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export interface DashboardProps {}

export default Dashboard;
