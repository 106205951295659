import React from 'react';
import {
  ArrayInput,
  Create,
  CreateProps,
  Datagrid,
  Edit,
  EditProps,
  FormDataConsumer,
  List,
  ListProps,
  NumberField,
  NumberInput,
  Show,
  ShowProps,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import PackrInput from '../custom-fields/PackrInput';
import PuzzleField from '../custom-fields/PuzzleField';

export const PackList = (props: ListProps) => {
  return (
    <List bulkActionButtons={false} exporter={false} {...props}>
      <Datagrid rowClick="show">
        <TextField source="title" />
        <TextField source="description" />
        <NumberField source="w" label="Width" />
        <NumberField source="h" label="Height" />
      </Datagrid>
    </List>
  );
};

export const PackShow = (props: ShowProps) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="title" />
        <TextField source="description" />
      </SimpleShowLayout>
    </Show>
  );
};

export const PackCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" required />
      <TextInput source="description" required />
      <NumberInput source="w" label="Width" min={3} max={5} required />
      <NumberInput source="h" label="Height" min={3} max={5} required />
    </SimpleForm>
  </Create>
);

export const PackEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="title" required />
      <TextInput source="description" required />
      <ArrayInput source="puzzles">
        <SimpleFormIterator disableAdd>
          <FormDataConsumer>
            {({getSource, scopedFormData}) => (
              <PuzzleField
                source={getSource?.('puzzle')}
                record={scopedFormData}
                label=""
              />
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <PackrInput label="Build" />
    </SimpleForm>
  </Edit>
);
