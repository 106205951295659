import * as React from 'react';
import {CSSProperties, FC, memo} from 'react';
import './Grid.scss';
import {Spectrum} from '../util/model';
import {range} from 'lodash';

const Grid: FC<GridFieldProps> = memo<GridFieldProps>(
  ({mapXYToStyle, onClick, spectrum}) => {
    const splitter = new RegExp(`.{1,${spectrum.w}}`, 'g');
    const rows = spectrum.letters.match(splitter)!.map((row) => row.split(''));
    const classes = ['spectrum'];
    if (onClick) {
      classes.push('clickable');
    }
    return (
      <table className={classes.join(' ')}>
        <tbody>
          <tr>
            <td className="no-border" />
            {range(spectrum.w).map((x) => (
              <td key={`col-${x}`} className="no-border">
                {spectrum.directions.charAt(spectrum.h + x) === '1' ? '▲' : '▼'}
              </td>
            ))}
          </tr>
          {rows.map((col, y) => (
            <tr key={`row-${y}`}>
              <td className="no-border">
                {spectrum.directions.charAt(y) === '1' ? '◀' : '▶'}
              </td>
              {col.map((c, x) => (
                <td
                  key={`col-${y}-${x}`}
                  style={mapXYToStyle && mapXYToStyle(x, y)}
                  onClick={onClick ? () => onClick(x, y) : undefined}
                >
                  {c}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
);

Grid.defaultProps = {
  mapXYToStyle: (_: number, __: number) => ({}),
};

export interface GridFieldProps {
  spectrum: Spectrum;
  mapXYToStyle?: (x: number, y: number) => CSSProperties;
  onClick?: (x: number, y: number) => void;
}

Grid.displayName = 'Grid';

export default Grid;
