import React, {FC, memo, useMemo} from 'react';
import {colorMap} from '../util/grid';
import {Mesh as MeshModel} from '../util/model';
import GridWord from './GridWord';

const MaskedMesh: FC<MaskedMeshProps> = memo<MaskedMeshProps>(({mesh}) => {
  // const sortedWords = useMemo(() => [...mesh.words].sort((a, b) => {
  //   return a.word.word.length - b.word.word.length;
  // }), [mesh.words])
  return (
    <div className="mesh">
      <div className="holder">
        {mesh.words.map(({word}, idx) => (
          <>
            <GridWord
              word={word.word}
              style={{
                backgroundColor: colorMap[idx],
              }}
            />
            {idx !== mesh.words.length - 1 && <div className="slash"/>}
          </>
        ))}
      </div>
    </div>
  );
});

export interface MaskedMeshProps {
  mesh: MeshModel;
}

export default MaskedMesh;
