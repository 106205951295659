import {sortBy} from 'lodash';
import React, {FC, memo} from 'react';
import {getColorizer} from '../util/grid';
import {
  Mesh,
  MeshWordRole,
  Puzzle as PuzzleModel,
  Spectrum,
} from '../util/model';
import Grid from './Grid';
import Word from './GridWord';
import MaskedMesh from './MaskedMesh';
import {WordList} from './WordList';

const Puzzle: FC<PuzzleProps> = memo<PuzzleProps>(({puzzle, onClick}) => {
  const bank = sortBy(
    puzzle.mesh.board.spectrum.letters
      .split('')
      .filter((_, i) => puzzle.mask[i] === '1')
  ).join('');

  const spectrum: Spectrum = {
    ...puzzle.mesh.board.spectrum,
    letters: puzzle.mesh.board.spectrum.letters
      .split('')
      .map((c, i) => {
        if (puzzle.mask[i] === '1') {
          return ' ';
        }
        return c;
      })
      .join(''),
  };

  const anagrams = puzzle.mesh.words.filter(
    ({role}) => role === MeshWordRole.Anagram
  );

  const anagramOffsets = anagrams.reduce<number[]>(
    (accumulator, value) => {
      accumulator.push(
        value.word.word.length + accumulator[accumulator.length - 1]
      );
      return accumulator;
    },
    [0]
  );

  const mesh: Mesh = {
    ...puzzle.mesh,
    words: anagrams.map((meshWord, idx) => ({
      ...meshWord,
      word: {
        ...meshWord.word,
        word: meshWord.word.word
          .split('')
          .map((c, i) => {
            if (puzzle.mesh_mask[i + anagramOffsets[idx]] === '1') {
              return ' ';
            }
            return c;
          })
          .join(''),
      },
    })),
  };

  const colorizer = getColorizer(puzzle.mesh.board.spectrum);
  return (
    <div className="divided">
      <div className="divided board tight">
        <MaskedMesh mesh={mesh} />
      </div>
      <div className="divided">
        <Grid
          spectrum={spectrum}
          mapXYToStyle={colorizer(puzzle.mesh.board)}
          onClick={onClick}
        />
      </div>
      <div className="divided">
        <Word word={bank} />
      </div>
      <WordList puzzle={puzzle} />
    </div>
  );
});

export interface PuzzleProps {
  puzzle: PuzzleModel;
  onClick?: (x: number, y: number) => void;
}

export default Puzzle;
