import * as React from 'react';
import {CSSProperties, FC, memo} from 'react';
import './Grid.scss';

const Word: FC<WordProps> = memo<WordProps>(({word, style}) => {
  return (
    <table className="spectrum">
      <tbody>
        <tr>
          {word.split('').map((c, y) => (
            <td key={`col-${y}`} style={style}>
              {c}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
});

Word.defaultProps = {
  style: undefined,
};

export interface WordProps {
  word: string;
  style?: CSSProperties;
}

Word.displayName = 'Word';

export default Word;
