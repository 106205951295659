interface Model {
  id: number;
}

export interface Word extends Model {
  word: string;
}

export enum MeshWordRole {
  Row = 1,
  Column = 2,
  Anagram = 3,
}

export interface MeshWord {
  word: Word;
  role: MeshWordRole;
  idx: number;
}

export interface Mesh extends Model {
  words: MeshWord[];
  board: Board;
  puzzle?: Puzzle;
}

export interface Board extends Model {
  partition: string;
  meshes?: Mesh[];
  spectrum: Spectrum;
}

export interface Spectrum extends Model {
  w: number;
  h: number;
  letters: string;
  boards: Board[];
  directions: string;
}

export interface Daily {
  date: string;
  role: 1;
  puzzle: Puzzle;
}

export interface Puzzle extends Model {
  mesh: Mesh;
  mask: string;
  mesh_mask: string;
  date?: string;
  alternatives?: Board[];
  difficulty?: number;
}

export interface PackPuzzle {
  pack_id: number;
  puzzle_id: number;
  puzzle?: Puzzle;
  sequence: number;
}

export interface Pack extends Model {
  title: string;
  description: string;
  w: number;
  h: number;
  puzzles?: PackPuzzle[];
}

export interface User extends Model {
  handle: string;
}

export interface AccessToken {
  access_token: string;
  user: User;
}
