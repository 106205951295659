import * as React from 'react';
import {FC, memo} from 'react';
import {
  fieldPropTypes,
  InjectedFieldProps,
  PublicFieldProps,
} from 'ra-ui-materialui/lib/field/types';
import {Spectrum} from '../util/model';
import Grid from '../components/Grid';

const SpectrumField: FC<SpectrumFieldProps> = memo<SpectrumFieldProps>(
  ({record = {}}) => {
    return <Grid spectrum={record as Spectrum} />;
  }
);

SpectrumField.defaultProps = {
  addLabel: true,
};

SpectrumField.propTypes = {
  ...fieldPropTypes,
};

export interface SpectrumFieldProps
  extends PublicFieldProps,
    InjectedFieldProps {}

SpectrumField.displayName = 'SpectrumField';

export default SpectrumField;
