import {PaginationProps} from 'ra-ui-materialui/lib/list/pagination/Pagination';
import React from 'react';
import {
  AutocompleteArrayInput,
  Datagrid,
  Edit,
  EditProps,
  Filter,
  FilterProps,
  List,
  ListProps,
  Loading,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceArrayInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useListContext,
} from 'react-admin';
import {exactFilter, fuzzyFilter, lteFilter} from '../util/filters';

const WordFilter = (props: Partial<FilterProps>) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn {...fuzzyFilter} />
    <NumberInput source="length" {...exactFilter} alwaysOn min={2} max={15} />
    <NumberInput
      label="Max level"
      source="level"
      alwaysOn
      {...lteFilter}
      min={1}
      max={3}
    />
    <NumberInput source="rating" {...exactFilter} alwaysOn min={1} max={3} />
  </Filter>
);

const WordTitle = ({record}: any) => {
  return <span>Word: {record.word}</span>;
};

const filterToQuery = (q: string) =>
  (q && {word: fuzzyFilter.parse!(q, '')}) || {};

export const WordEdit = (props: EditProps) => {
  return (
    <Edit title={<WordTitle />} undoable={false} {...props}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <TextInput disabled source="word" />
        <SelectInput
          source="level"
          choices={[
            {
              id: 1,
              name: 'use',
            },
            {
              id: 2,
              name: 'questionable',
            },
            {
              id: 3,
              name: 'do not use',
            },
          ]}
        />
        <SelectInput
          source="rating"
          choices={[
            {
              id: 1,
              name: 'okay',
            },
            {
              id: 2,
              name: 'questionable',
            },
            {
              id: 3,
              name: 'do not use',
            },
          ]}
        />
        <ReferenceArrayInput
          source="roots"
          reference="words"
          label="Root(s)"
          filterToQuery={filterToQuery}
          perPage={5}
          sort={{field: 'word', order: 'ASC'}}
        >
          <AutocompleteArrayInput optionText="word" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

const WordPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
);

export const WordGrid = () => {
  const {loading} = useListContext();
  if (loading) {
    return <Loading />;
  }

  return (
    <Datagrid rowClick="edit">
      <TextField source="word" />
      <NumberField source="level" />
    </Datagrid>
  );
};

export const WordList = (props: ListProps) => (
  <List
    filters={<WordFilter />}
    bulkActionButtons={false}
    perPage={100}
    pagination={<WordPagination />}
    {...props}
  >
    <WordGrid />
  </List>
);
