import simpleRest from 'ra-data-simple-rest';
import {DataProvider, fetchUtils} from 'ra-core';
import {stringify, parse} from 'query-string';
import {Buffer} from 'buffer';

export const baseUrl = process.env.REACT_APP_API_BASE_URL!;
export const authBaseUrl = baseUrl.replace('/admin', '/auth');

export const unobfuscate = (key: string, value: string): string => {
  let encoded = '';
  for (let cursor = 0; cursor < value.length; cursor++) {
    if (cursor % 2 === 0) {
      encoded += String.fromCharCode(key.charCodeAt(cursor / 2) - 1);
    } else {
      encoded += String.fromCharCode(value.charCodeAt(cursor) + 1);
    }
  }

  return JSON.parse(Buffer.from(encoded, 'base64').toString());
};

export const httpClient = async (
  url: any,
  options?: fetchUtils.Options
): Promise<{
  status: number;
  headers: Headers;
  body: string;
  json: any;
}> => {
  options = options || {};
  const headers = (options.headers as Headers) || new Headers();

  // Sign requests.
  const ts = Math.floor(Date.now() / 1000);
  const ss = unobfuscate(
    process.env.REACT_APP_SIGNING_SECRET_KEY!,
    process.env.REACT_APP_SIGNING_SECRET_VALUE!
  );

  const key = await crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(ss),
    {
      name: 'HMAC',
      hash: {name: 'SHA-256'},
    },
    true,
    ['sign']
  );
  const parsedUrl = new URL(window.origin + url);
  const val = await crypto.subtle.sign(
    'HMAC',
    key,
    new TextEncoder().encode(`${ts}:${parsedUrl.pathname}${parsedUrl.search}`)
  );
  const signature = Array.from(new Uint8Array(val))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  headers.set('X-Timestamp', ts.toString());
  headers.set('X-Signature', signature);

  options.headers = headers;
  options.credentials = 'include';
  return fetchUtils.fetchJson(url, options);
};

export const baseDataProvider = simpleRest(baseUrl, httpClient);
export const dataProvider: DataProvider = {
  ...baseDataProvider,
  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({id: {op: '∈', value: params.ids}}),
    };
    const url = baseUrl + '/' + resource + '?' + stringify(query);
    return httpClient(url).then(({json}) => ({data: json}));
  },
  getOne: (resource, params) => {
    const data = parse(window.location.search.substring(1));
    const search = data && data.filter && stringify({filter: data.filter});
    return httpClient(
      baseUrl + '/' + resource + '/' + params.id + (search ? '?' + search : '')
    ).then(({json}) => ({data: json}));
  },
};
