import {Grid} from '@material-ui/core';
import {DefaultTheme} from '@material-ui/styles/defaultTheme';
import React, {FC, memo} from 'react';
import {useLogin, useNotify, userLogin} from 'react-admin';
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import {connect} from 'react-redux';

const Login: FC<LoginProps> = memo<LoginProps>(({theme}) => {
  const login = useLogin();
  const notify = useNotify();

  const responseGoogle = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    login((response as GoogleLoginResponse).accessToken).catch(() => {
      notify('Unable to sign in.');
    });
  };

  const errorGoogle = (error: any) => {
    console.error(error);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{minHeight: '100vh'}}
    >
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}
        hostedDomain="fiddlebrix.com"
        buttonText="Log in"
        onSuccess={responseGoogle}
        onFailure={errorGoogle}
        cookiePolicy={'single_host_origin'}
      />
    </Grid>
  );
});

interface LoginProps {
  theme: Partial<DefaultTheme>;
}

export default connect(undefined, {userLogin})(Login);
