import * as React from 'react';
import {FC, memo} from 'react';
import {
  fieldPropTypes,
  InjectedFieldProps,
  PublicFieldProps,
} from 'ra-ui-materialui/lib/field/types';
import {Spectrum} from '../util/model';
import Grid from '../components/Grid';
import './BoardsField.scss';
import {getColorizer} from '../util/grid';

const BoardsField: FC<BoardsFieldProps> = memo<BoardsFieldProps>(
  ({record = {}}) => {
    const spectrum = record as Spectrum;
    const colorizer = getColorizer(spectrum);
    return (
      <div>
        {spectrum.boards.map((board) => {
          return (
            <div key={board.id} className="floating-spectrum">
              <Grid spectrum={spectrum} mapXYToStyle={colorizer(board)} />
            </div>
          );
        })}
      </div>
    );
  }
);

BoardsField.defaultProps = {
  addLabel: true,
};

BoardsField.propTypes = {
  ...fieldPropTypes,
};

export interface BoardsFieldProps
  extends PublicFieldProps,
    InjectedFieldProps {}

BoardsField.displayName = 'BoardsField';

export default BoardsField;
